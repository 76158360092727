import React, {useRef}  from "react"

import Layout from "../../../../../components/layout"
import SEO from "../../../../../components/seo"



const LeadRubberBearings = () => {
  //javascript
  const title = 'Lead Rubber Bearings';
  const metatitle = 'Lead Rubber Bearings';
  const description = 'Lead Rubber Bearings : Limit states and threshold values ';
  const metadescription = description;
  const style2 = {
    marginBottom: 80
  }

  const scrollToRef = (ref) => window.scrollTo({
		left:0,
		top:ref.current.offsetTop,
		behavior: 'smooth'
	});
  const Ref1 = useRef(null);
  const Ref2 = useRef(null);
  const Ref3 = useRef(null);
  

	const executeScroll = (e, ref) => {
		e.preventDefault();
		scrollToRef(ref);
  }
  return(
    <Layout bodyclass="ls-experimental" title={title}>
      <SEO
        title={metatitle}
        description={metadescription}
      />
      <nav className="anchornav">
        <ul>
          <li>
            <a className="more" href="../elastomeric-bearings">Elastomeric Bearings</a>
          </li>
          <li>
            <a className="more actclass" href="../lead-rubber-bearings">Lead Rubber Bearings (LRB)</a>
          </li>
          <li>
            <a className="more" href="../elastomeric-bearings-ptfe">Elastomeric Bearings with PTFE Sheet</a>
          </li>
          <li>
            <a className="more" href="../steel-bearings">Steel Bearings</a>
          </li>
        </ul>
      </nav>
      <div className="content-table">
        <h2>Table 2: Lead Rubber Bearings (LRB): Limit states and threshold values</h2>
        <h3>(R): limit state definition based on soa ,(E): Experimental, (S): Simulation or analysis</h3>
        <table>
          <thead>
            <tr>
              <th colSpan={4}>Engineering Demand Parameter: Shear deformation γ (%)</th>
            </tr>
            <tr>
              <th>Reference</th>
              <th>Limit State</th>
              <th>Shear deformation (γ)</th>
              <th>Description</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td><button onClick={ e => executeScroll(e, Ref1)}>[1]</button>. (E) Bousias, E., Palios, X., Alexakis Ch., Strepelias, E., Fardis, M., Raptopoulos, S. (2008)</td>
              <td>LS3</td>
              <td>170%</td>
              <td>Detachment of elastomers and steel plates</td>
            </tr>
            <tr className="even">
              <td><button onClick={ e => executeScroll(e, Ref2)}>[2]</button>. (E) Mori, A., Moss, P.J., Cooke, N., Carr, A. J. (1999) <a href="https://dx.doi.org/10.12989/sem.1997.5.4.451" target="_blank">https://dx.doi.org/10.12989/sem.1997.5.4.451</a></td>
              <td>LS3</td>
              <td>200%</td>
              <td>Bearing Uplift</td>
            </tr>
            <tr>
              <td rowSpan={4}><button onClick={ e => executeScroll(e, Ref3)}>[3]</button>. (S+R) Zhang, J. and Huo, Y. (2009) <a href="https://doi.org/10.1016/j.engstruct.2009.02.017" target="blank">https://doi.org/10.1016/j.engstruct.2009.02.017</a></td>
              <td>LS1</td>
              <td>100%</td>
              <td>Slight damage (strain limit for linear behavior of rubber)</td>
            </tr>
            <tr>
              <td>LS2</td>
              <td>150%</td>
              <td>Moderate damage</td>
            </tr>
            <tr>
              <td>LS3</td>
              <td>200%</td>
              <td>Extensive damage (Initiation of hardening of elastomeric material)</td>
            </tr>
            <tr>
              <td>LS4</td>
              <td>250%</td>
              <td>Complete damage (significant pounding or unseating)</td>
            </tr>
          </tbody>
          </table>
        </div>
        <div className="references">
          <h4>References</h4>
          <ol>
            <li ref={Ref1}>Bousias, E., Palios, X., Alexakis, C., Strepelias, I., Fardis, M., Raptopoulos, S. (2008). Experimental and analytical study of seismically isolated bridges with or without additional damping, 3rd Hellenic Conference on Earthquake Engineering and Engineering Seismology, November 5-7, 2008 (in Greek).</li>
            <li ref={Ref2}>Mori, A., Moss, P. J., Cooke, N., Carr, A. J. (1999). The Behavior of Bearings Used for Seismic Isolation under Shear and Axial Load, Earthquake Spectra, Vol. 15, No. 2, pp 199-224, https://doi.org/10.1193%2F1.1586038. </li>
            <li ref={Ref3}>Zhang, J. and Huo, Y. (2009). Evaluating effectiveness and optimum design of isolation devices for highway bridges using the fragility function method, Engineering Structures, Vol. 31, pp 1648-1660, https://doi.org/10.1016/j.engstruct.2009.02.017.</li>
          </ol>
        </div>
      
    </Layout>
  )
}

export default LeadRubberBearings
